import React from "react";
import "./InstructionsCard.scss";
import { Link } from "gatsby";

const Instructions = ({ ingredients, steps, pad, fromRecipe }) => {
  return (
    <>
      {ingredients.length < 1 ? null : (
        <>
          <div className={pad === "true" ? "left row inner pad" : "left"}>
            <div className="checkList">
              {fromRecipe ? (
                <span className="title">What you need</span>
              ) : (
                <h2>What you need</h2>
              )}
              <div className="list-container">
                {ingredients.map((item, index) => (
                  <div key={index} className="transparent">
                    <input value={item} type="checkbox" className="checkbox" />
                    <span className="not-checked-item">
                      {item.sentence.map((sentence) =>
                        sentence.path ? (
                          <span>
                            <Link className="link" to={sentence.path}>
                              {sentence.text}{" "}
                            </Link>
                          </span>
                        ) : (
                          <span>{sentence.text}</span>
                        )
                      )}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="right">
            <div className="checkList">
              {fromRecipe ? (
                <span className="title">How to Make it</span>
              ) : (
                <h2>How to Make it</h2>
              )}

              <div className="list-container">
                {steps.map((item, index) => (
                  <div key={index} className="transparent">
                    <span>{index + 1}.</span>
                    <span className="not-checked-item">{item}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Instructions;
