import React from "react";
import { Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { data } from "../data/blogPostData/blogData";

import Instructions from "../components/InstructionsCard/InstructionsCard";
import ExploreButtons from "../components/ExploreButtons/ExploreButtons";
import { blogPosts } from "../data/blogposts";
import "./articleBlogPost.scss";
import { Link } from "gatsby";
import VideoPlayer from "../components/VideoPlayer/VideoPlayer";

const ArticleBlogPost = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;


  const formatDate = (date) => {
    const updateDate = new Intl.DateTimeFormat("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    }).format(new Date(date));
    return updateDate;
  };

  return (
    <Layout title={blogPosts[i].blogTitle} description="">
      <div className="top"></div>

      <article className="article-blog-post">
        <Row
          as={"section"}
          className={
            blogPosts[i].blogPostArticle.blogPostBody[0].showSection
              ? "article-blog-post--header"
              : "article-blog-post--header remove-margin-bottom"
          }
        >
          <h1 className="article-blog-post--header--title">
            {blogPosts[i].blogTitle}
          </h1>
          <div className=" article-blog-post--header--date margin-top-bottom">
            <small>{blogPosts[i].blogDate}</small>
          </div>
          {blogPosts[i].blogPostArticle.blogPostHeader.map((item, j) => (
            <>
              <div
                style={
                  item.headerPost[0].text
                    ? { display: "block" }
                    : { display: "none" }
                }
                className="article-blog-post--header--post margin-top-bottom"
              >
                {j === 1 && item.image ? (
                  <div className="article-blog-post--header--image-container">
                    <img
                      loading="lazy"
                      style={{
                        objectFit: "cover",
                      }}
                      className="article-blog-post--header--image-container--image"
                      src={item.image}
                      alt={blogPosts[i].blogTitle}
                    />
                  </div>
                ) : j === 1 && item.video ? (
                  <div className="article-blog-post--header--video-container">
                    <VideoPlayer link={data[i].link} />{" "}
                  </div>
                ) : null}
                {item.headerPost.map((post) => (
                  <>
                    {post.path ? (
                      <Link className="article-blog-post-link" to={post.path}>
                        {post.text}
                      </Link>
                    ) : (
                      post.text
                    )}{" "}
                  </>
                ))}
              </div>
            </>
          ))}
        </Row>
        <Row
          as={"section"}
          md={2}
          sd={1}
          ld={2}
          className="article-blog-post--body"
        >
          {blogPosts[i].blogPostArticle.blogPostBody
            ? blogPosts[i].blogPostArticle.blogPostBody.map((item, j) =>
                item.showSection ? (
                  <>
                    {item.image ? (
                      <div className="article-blog-post--body--image-container mobile-display">
                        <img
                          loading="lazy"
                          style={{ objectFit: "cover" }}
                          className="article-blog-post--body--image-container--image"
                          src={item.image}
                          alt={blogPosts[i].blogTitle}
                        />
                      </div>
                    ) : null}

                    {/* if multiple recipes, flip flop images */}
                    {j % 2 === 0 &&
                    item.image &&
                    blogPosts[i].blogPostArticle.hasMultipleRecipes ? (
                      <div className="article-blog-post--body--image-container no-mobile-display">
                        <img
                          loading="lazy"
                          style={{
                            objectFit: "cover",
                          }}
                          className="article-blog-post--body--image-container--image"
                          src={item.image}
                          alt={blogPosts[i].blogTitle}
                        />
                      </div>
                    ) : null}

                    {/* if only one recipe, align all images to the left */}
                    {item.image &&
                    !blogPosts[i].blogPostArticle.hasMultipleRecipes ? (
                      <div className="article-blog-post--body--image-container no-mobile-display padd">
                        <img
                          loading="lazy"
                          style={{ objectFit: "cover" }}
                          className="article-blog-post--body--image-container--image"
                          src={item.image}
                          alt={blogPosts[i].blogTitle}
                        />
                      </div>
                    ) : null}

                    <div
                      className={
                        !blogPosts[i].blogPostArticle.hasMultipleRecipes
                          ? "article-blog-post--body--text-section "
                          : j % 2 === 0
                          ? "article-blog-post--body--text-section section-text-zero-padding-right"
                          : "article-blog-post--body--text-section section-text-zero-padding-left"
                      }
                    >
                      <div className="article-blog-post--body--text-section--header-container">
                        <h2 className="article-blog-post--body--text-section--header-container--header">
                          {item.sectionHeader}
                        </h2>
                      </div>
                      {item.sectionPost.map((item) => (
                        <div className="article-blog-post--body--text-section--body">
                          {item.bold ? (
                            <span className="article-blog-post--body--text-section--body--bold">
                              {item.bold}{" "}
                            </span>
                          ) : null}
                          {item.post.map((post) => (
                            <span className="article-blog-post--body--text-section--body--text">
                              {post.path ? (
                                <Link
                                  className="article-blog-post-link"
                                  to={post.path}
                                >
                                  {post.text}
                                </Link>
                              ) : (
                                post.text
                              )}{" "}
                            </span>
                          ))}
                        </div>
                      ))}
                    </div>
                    {j % 2 !== 0 &&
                    item.image &&
                    blogPosts[i].blogPostArticle.hasMultipleRecipes ? (
                      <div className="article-blog-post--body--image-container no-mobile-display">
                        <img
                          loading="lazy"
                          style={{
                            objectFit: "cover",
                          }}
                          className="article-blog-post--body--image-container--image"
                          src={item.image}
                          alt={blogPosts[i].blogTitle}
                        />
                      </div>
                    ) : null}

                    {item.blogPostRecipe ? (
                      <>
                        <Instructions
                          ingredients={item.blogRecipe}
                          steps={item.blogInstructions}
                        />
                        <hr className="solid-hr no-mobile-display"></hr>
                        <hr className="solid-hr"></hr>
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    {item.blogPostRecipe ? (
                      <>
                        <Instructions
                          ingredients={item.blogRecipe}
                          steps={item.blogInstructions}
                        />
                        <hr className="solid-hr no-mobile-display"></hr>
                        <hr className="solid-hr"></hr>
                      </>
                    ) : (
                      <>
                        <hr className="solid-hr no-mobile-display"></hr>
                        <hr className="solid-hr"></hr>
                      </>
                    )}
                  </>
                )
              )
            : null}
        </Row>
        {/* <hr className="solid-hr"></hr> */}
        {blogPosts[i].blogPostAuthor ? (
          <div className="author-container">
            <div className="author-container--inner-author-container">
              <div className="author-container--inner-author-container--layout author-container--inner-author-container--layout--title">
                {blogPosts[i].blogTitle}
              </div>
              <div className="author-container--inner-author-container--layout author-container--inner-author-container--layout--recipe-by">
                Recipe By:{" "}
                {blogPosts[i].originalBlogLink ? (
                  <Link
                    className="article-blog-post-link"
                    to={blogPosts[i].originalBlogLink}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {blogPosts[i].blogPostAuthor}
                  </Link>
                ) : (
                  blogPosts[i].blogPostAuthor
                )}
              </div>
              <div className="author-container--inner-author-container--layout author-container--inner-author-container--layout--date">
                Date Created: {formatDate(blogPosts[i].blogDate)}
              </div>
            </div>
          </div>
        ) : null}
        <ExploreButtons id={i} />
      </article>
    </Layout>
  );
};

export default ArticleBlogPost;
